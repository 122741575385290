import {Col, Row} from "antd";
import {TFunction, withTranslation} from "react-i18next";
import {SvgIcon} from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  Chat,
  Extra,
  FooterContainer,
  FooterSection,
  Language,
  Large,
  LogoContainer,
  NavLink,
  Para,
  Title,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={6} md={6} sm={6} xs={6}>
              <Language>{t("Contact")}</Language>
              {/*<Large to="/">{t("Tell us everything")}</Large>*/}
              <Para>
                {t(`admin@vangamatechnologies.com`)}
              </Para>
              <a href="mailto:admin@vangamatechnologies.com">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            {/*<Col lg={8} md={8} sm={12} xs={12}>*/}
            {/*  <Title>{t("Policy")}</Title>*/}
            {/*  <Large to="/">{t("Application Security")}</Large>*/}
            {/*  <Large to="/">{t("Software Principles")}</Large>*/}
            {/*</Col>*/}
            {/*<Col lg={8} md={8} sm={12} xs={12}>*/}
            {/*  <Title>{t("Company")}</Title>*/}
            {/*  <Large to="/" >{t("About")}</Large>*/}
            {/*  /!*<Large to="/">{t("Blog")}</Large>*!/*/}
            {/*  /!*<Large to="/">{t("Press")}</Large>*!/*/}
            {/*  /!*<Large to="/">{t("Careers & Culture")}</Large>*!/*/}
            {/*</Col>*/}
            {/*<Col lg={6} md={6} sm={12} xs={12}>*/}
            {/*  <Empty />*/}
            {/*  <Large to="/">{t("Support Center")}</Large>*/}
            {/*  <Large to="/">{t("Customer Support")}</Large>*/}
            {/*</Col>*/}
            <Col lg={6} md={6} sm={6} xs={6}>
              <Title>{t("Address")}</Title>
              <Para>Woodmead</Para>
              <Para>Sandton</Para>
            </Col>
          </Row>
          {/*<Row justify="space-between">*/}
          {/*  <Col lg={10} md={10} sm={12} xs={12}>*/}
          {/*    <Empty />*/}
          {/*    <Language>{t("Address")}</Language>*/}
          {/*    <Para>Woodmead</Para>*/}
          {/*    <Para>Sandton</Para>*/}
          {/*  </Col>*/}
          {/*  /!*<Col lg={8} md={8} sm={12} xs={12}>*!/*/}
          {/*  /!*  <Title>{t("Company")}</Title>*!/*/}
          {/*  /!*  <Large to="/" >{t("About")}</Large>*!/*/}
          {/*  /!*  /!*<Large to="/">{t("Blog")}</Large>*!/*!/*/}
          {/*  /!*  /!*<Large to="/">{t("Press")}</Large>*!/*!/*/}
          {/*  /!*  /!*<Large to="/">{t("Careers & Culture")}</Large>*!/*!/*/}
          {/*  /!*</Col>*!/*/}
          {/*  /!*<Col lg={6} md={6} sm={12} xs={12}>*!/*/}
          {/*  /!*  <Label htmlFor="select-lang">{t("Language")}</Label>*!/*/}
          {/*  /!*  <LanguageSwitchContainer>*!/*/}
          {/*  /!*    <LanguageSwitch onClick={() => handleChange("en")}>*!/*/}
          {/*  /!*      <SvgIcon*!/*/}
          {/*  /!*        src="united-states.svg"*!/*/}
          {/*  /!*        aria-label="homepage"*!/*/}
          {/*  /!*        width="30px"*!/*/}
          {/*  /!*        height="30px"*!/*/}
          {/*  /!*      />*!/*/}
          {/*  /!*    </LanguageSwitch>*!/*/}
          {/*  /!*    <LanguageSwitch onClick={() => handleChange("es")}>*!/*/}
          {/*  /!*      <SvgIcon*!/*/}
          {/*  /!*        src="spain.svg"*!/*/}
          {/*  /!*        aria-label="homepage"*!/*/}
          {/*  /!*        width="30px"*!/*/}
          {/*  /!*        height="30px"*!/*/}
          {/*  /!*      />*!/*/}
          {/*  /!*    </LanguageSwitch>*!/*/}
          {/*  /!*  </LanguageSwitchContainer>*!/*/}
          {/*  /!*</Col>*!/*/}
          {/*</Row>*/}
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="vlogo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://www.linkedin.com/in/vangama-technologies/"
                src="linkedin.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
